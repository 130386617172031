import React from 'react';
import { Route, Switch } from "react-router";
import Loadable from 'react-loadable';
import { Spin } from 'antd';

function Loading({ error }) {
  if (error) {
    return  <div className="mdle mnheight">An Error occured!!</div>;
  } else {
    return <Spin className="mdle mnheight w-100"/>;
  }
}
export const Home = Loadable({
  loader: () => import('../home'),
  loading: Loading
});
export const Orders = Loadable({
  loader: () => import('../order'),
  loading: Loading
});
export const Wallet = Loadable({
  loader: () => import('../wallet'),
  loading: Loading
});
export const CreateOrder = Loadable({
  loader: () => import('../order/sendPackage'),
  loading: Loading
});
export const TrackOrder = Loadable({
  loader: () => import('../trackOrder'),
  loading: Loading
});
export const CheckPrice = Loadable({
  loader: () => import('../order/checkPrice'),
  loading: Loading
});
export const Notification = Loadable({
  loader: () => import('../notification'),
  loading: Loading
});
export const Profile = Loadable({
  loader: () => import('../profile'),
  loading: Loading
});
export const WalletCard = Loadable({
  loader: () => import('../wallet/Wallet'),
  loading: Loading
});
class Routes extends React.Component {
  render(){
    return (
        <Switch>
          <Route exact path="/user"  component={Home} />
          <Route exact path="/user/orders"  component={Orders} />
          <Route exact path="/user/wallet"  component={Wallet} />
          <Route exact path="/user/createorder" component={CreateOrder} />
          <Route exact path="/user/trackorder" component={TrackOrder } />
          <Route exact path= "/user/check-price" component={CheckPrice} />
          <Route exact path="/user/notification" component={Notification} />
          <Route exact path="/user/profile" component={Profile} />
          </Switch>

    );
}
}
export default Routes;
